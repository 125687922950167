import React from "react"
import { graphql, useStaticQuery } from "gatsby"
import "../styles/main.scss"
import { Helmet } from "react-helmet"
import Layout from "../components/Layout"
import PageHeader from "../components/PageHeader"
import WhyTint from "../components/WhyTint"
import PageCarousel from "../components/PageCarousel"

export default function Home() {
  const data = useStaticQuery(graphql`
    query {
      file(relativePath: { eq: "images/Fotolia_107950533_high-res-BW.jpg" }) {
        childImageSharp {
          fluid(maxWidth: 1920) {
            ...GatsbyImageSharpFluid
          }
        }
      }
    }
  `)

  const sliderImages = [
    { alt: "Gold film shop front", fileName: "Gold-film-shop-front.jpg" },
    {
      alt: "Frosted film cut design",
      fileName: "Frosted-film-cut-design-e1547628050470.jpg",
    },
    {
      alt: "Glass wall in the office building",
      fileName: "Fotolia_107950533_high-res-BW.jpg",
    },
  ]

  return (
    <>
      <Helmet>
        <title>Commercial | Window Magic</title>
        <meta
          name="description"
          content="We have a great range of products for your office, such as your company brand motifs for internal glass walls."
        />
      </Helmet>

      <Layout hidePageHeader={true}>
        <PageHeader
          fluid={data.file.childImageSharp.fluid}
          alt="Office window"
          title="Commercial"
        />

        <section className="page-section">
          <div className="container">
            <div className="row justify-content-between">
              <div className="col-lg-6">
                <p>We have a great range of products for your workplace.</p>
                <p>
                  With today’s technology, work places have expensive equipment
                  and break ins are a threat. This risk can be minimised with
                  reflective or frosted tints as your employees, stock and
                  equipment are hidden from prying eyes.
                </p>
                <p>
                  Internal glass partitions can make your office or workstation
                  look and feel very exposed. We offer a range of decorative
                  films to give your work space privacy and a polished
                  professional look.
                </p>
                <p>
                  Window tinting blocks up to 99% of UV rays, protecting workers
                  from glare and providing a comfortable working environment.
                </p>
                <p>
                  <a href="tel:+64274449914">Call us now</a> to discuss how
                  Window Magic can improve your workplace.
                </p>
              </div>
              <div className="col-lg-5 d-flex flex-column justify-content-center">
                <PageCarousel imagesToDisplay={sliderImages} />
              </div>
            </div>
          </div>
        </section>

        <WhyTint />
      </Layout>
    </>
  )
}
